import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import helpbg from "../../src/images/helpbg.png";
import heartsambol from "../../src/images/heartsambol.png";
import arrow1 from "../../src/images/arrow1.png";
import arrow2 from "../../src/images/arrow2.png";
import arrow3 from "../../src/images/arrow3.png";
import arrow4 from "../../src/images/arrow4.png";

const useStyles = makeStyles({
  main__div: {
    background: `url(${helpbg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    paddingLeft: "3rem",
    marginTop: "-5px",
    paddingBottom: "14rem",
    "@media(max-width : 900px)": {
      padding: "0rem 1rem 0rem 2rem",
      "@media(max-width : 600px)": {
        padding: "0rem 1rem 0rem 1rem",
      },
    },
  },
  h6: {
    color: "#1480cb",
    textAlign: "left",
    fontSize: "38px !important",
    "@media(max-width : 600px)": {
      fontSize: "24px !important",
      textAlign: "center",
    },
  },
  sub__head: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "26px !important",
    marginTop: "1.5rem !important",
    "@media(max-width : 600px)": {
      textAlign: "left",
      marginTop: "0rem !important",
    },
  },
  sub__head4: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "26px !important",
    marginTop: "1rem !important",
    "@media(max-width : 600px)": {
      textAlign: "left",
      marginTop: "0rem !important",
    },
  },
  sub__head2: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "26px !important",
    marginTop: "1rem !important",
    "@media(max-width : 600px)": {
      textAlign: "left",
      marginTop: "0rem !important",
    },
  },
  help__cont: {
    textAlign: "left",
  },
  p: {
    fontSize: "18px !important",

    marginTop: "1rem !important",
    "@media(max-width : 1200px)": {
      fontSize: "17px !important",
      "@media(max-width : 600px)": {
        fontSize: "18px !important",
        textAlign: "left",
      },
    },
  },

  boxHeight: {
    height: "100px",
    "@media(max-width : 600px)": {
      height: "0px",
    },
  },
  br: {
    "@media(max-width : 600px)": {
      display: "none !important",
    },
  },
  hearbeat: {
    width: "15%",
    "@media(max-width : 900px)": {
      width: "40%",
      "@media(max-width : 600px)": {
        width: "50%",
      },
    },
  },
  res__hding: {
    display: "flex",
    gap: "1.5rem",
    "@media(max-width : 600px)": {
      gap: "0.6rem",
      marginTop: "1rem",
    },
  },
  res__hding2: {
    display: "flex",
    gap: "1.5rem",
    // marginBottom: '1rem',
    "@media(max-width : 600px)": {
      gap: "0.6rem",
      marginTop: "1rem",
    },
  },
  arrow__width: {
    "@media(max-width : 600px)": {
      width: "25px",
    },
  },
});

const Help = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.main__div}>
        <Grid container spacing={0}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <Box className={classes.boxHeight} /> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "2rem",
                "@media(max-width : 900px)": {
                  display: "inherit",
                  textAlign: "center",
                },
              }}
            >
              <Box
                component={"img"}
                src={heartsambol}
                className={classes.hearbeat}
              />

              <Typography
                sx={{ "@media(max-width : 900px)": { textAlign: "center" } }}
                className={classes.h6}
                variant="h4"
              >
                RespiMatters
                <Typography fontSize={8} component={"sup"}>
                  TM
                </Typography>{" "}
                Can Help You Improve{" "}
                <Box className={classes.br} component={"br"} /> Your Breathing.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={1.4} md={1.4} sm={12} xs={12}></Grid>
          <Grid item lg={8.6} md={8.6} sm={12} xs={12}>
            <Box className={classes.help__cont}>
              {/* <Box className={classes.res__hding}>
                                <Box> <Box className={classes.arrow__width} sx={{ opacity: '0', marginTop: '-2px', '@media(max-width : 600px)': { marginTop: '-5px' } }} component={'img'} src={arrow1} /></Box>
                                <Box>


                                </Box>
                            </Box> */}
              <Box
                sx={{
                  height: "30px",
                  "@media(max-width : 600px)": { height: "20px" },
                }}
              />

              <Box className={classes.res__hding2}>
                <Box>
                  {" "}
                  <Box
                    className={classes.arrow__width}
                    sx={{
                      marginTop: "-2px",
                      "@media(max-width : 600px)": { marginTop: "-5px" },
                    }}
                    component={"img"}
                    src={arrow1}
                  />
                </Box>
                <Box>
                  <Typography
                    color={"#2b358e"}
                    variant="h6"
                    className={classes.sub__head4}
                  >
                    {" "}
                    Learn breathing habits consistent with good respiration.
                  </Typography>
                  <Typography color={"#187e78"} className={classes.p}>
                    {" "}
                    RespiMatters
                    <Typography fontSize={8} component={"sup"}>
                      TM
                    </Typography>{" "}
                    identifies breathing habits that negatively affect health
                    and performance and then assists you with learning breathing
                    that optimize respiration, enhancing wellness and
                    capabilities.
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.res__hding}>
                <Box>
                  {" "}
                  <Box
                    className={classes.arrow__width}
                    sx={{
                      marginTop: "6px",
                      "@media(max-width : 600px)": { marginTop: "10px" },
                    }}
                    component={"img"}
                    src={arrow3}
                  />
                </Box>
                <Box>
                  <Box
                    sx={{ "@media(max-width : 600px)": { marginTop: "1rem" } }}
                  />
                  <Typography
                    color={"#2b358e"}
                    variant="h6"
                    className={classes.sub__head}
                  >
                    {" "}
                    Why risk guessing about the effects your breathing habits
                    can have on your health when you don’t have to?
                  </Typography>
                  <Typography color={"#187e78"} className={classes.p}>
                    Experience technology and personalized appointments that
                    provide observable, measurable, and relevant data with
                    personalized expertise and science driven learning
                    solutions.
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.res__hding}>
                <Box>
                  {" "}
                  <Box
                    className={classes.arrow__width}
                    sx={{
                      marginTop: "5px",
                      "@media(max-width : 600px)": { marginTop: "-5px" },
                    }}
                    component={"img"}
                    src={arrow2}
                  />
                </Box>
                <Box>
                  <Typography
                    color={"#2b358e"}
                    variant="h6"
                    className={classes.sub__head}
                  >
                    {" "}
                    Comfortable, wearable, breathing monitor.
                  </Typography>

                  <Typography color={"#187e78"} className={classes.p}>
                    During the analysis and learning services at RespiMatters
                    <Typography fontSize={8} component={"sup"}>
                      TM
                    </Typography>{" "}
                    you are provided with a rental instrument that includes
                    hardware and software to monitor your breathing physiology.
                    The monitor is wearable and operates with Bluetooth or USB
                    capabilities. The instrument software operates on Apple and
                    PC computers, tablets, and smart phone technology.
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.res__hding}>
                <Box>
                  {" "}
                  <Box
                    className={classes.arrow__width}
                    sx={{
                      marginTop: "6px",
                      "@media(max-width : 600px)": { marginTop: "-5px" },
                    }}
                    component={"img"}
                    src={arrow4}
                  />
                </Box>
                <Box>
                  <Typography
                    color={"#2b358e"}
                    variant="h6"
                    className={classes.sub__head}
                  >
                    Personalized, caring, one-on-one tele-visits.
                  </Typography>

                  <Typography color={"#187e78"} className={classes.p}>
                    {" "}
                    We all learn breathing habits. What are yours and how are
                    they affecting you?
                    <br />
                    Find out by scheduling a personalized tele-appointment with
                    Sandra Reamer, one of the originators of Applied Breathing
                    Behavior Analyst and CapnoLearning
                    <Typography fontSize={8} component={"sup"}>
                      TM
                    </Typography>
                    .
                  </Typography>
                  <Typography color={"#187e78"} className={classes.p}>
                    Sandra and her team will collaborate with you to personalize
                    a plan that is tailored to assist in identifying and then
                    improving your breathing mechanics, respiratory chemistry,
                    and the physical, cognitive, emotional, and behavioral
                    benefits that enhance your partnership with body and mind
                    when promoting health.
                  </Typography>
                  {/* <Typography color={'#187e78'} className={classes.p}> The choices you make regarding your breathing can be informed, specific, and directed to chart a new course forward benefiting the intelligence of psychology when breathing and good respiration matters. We're here for you.
                                    </Typography> */}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Help;
